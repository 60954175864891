/**=====================
    78. Sidebar CSS Start
==========================**/

%sidebar-after {
    position: absolute;
    content: "";
    top: 48%;
    width: 8px;
    border-top: 1px solid rgba($theme-body-sub-title-color, 0.4);
}

%sidebar-active-dot {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    border-radius: 100%;
    background: var(--theme-deafult);
    opacity: 0;
    visibility: hidden;
    [dir="rtl"] & {
        left: unset;
        right: 25px;
    }
}

%sidebar-before {
    position: absolute;
    content: "";
    width: 1px;
    height: 0;
    background: rgba($theme-body-sub-title-color, 0.4);
    top: -10px;
    left: 27px;
    transition: height 0.5s;
    [dir="rtl"] & {
        left: unset;
        right: 27px;
    }
}

%horizontal-after {
    position: absolute;
    content: "";
    top: 49%;
    width: 5px;
    height: 1px;
    border-radius: 100%;
    background-color: #59667a;
}

%for-animated-hover {
    box-shadow: $main-header-shadow;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0;
}

%for-animated-hover-box {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    border-radius: 5px;
    overflow: hidden;
    &:before {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $white;
        content: "";
        top: -7px;
        position: absolute;
        left: 10px;
        z-index: 2;
    }
    &:after {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $light-background;
        content: "";
        top: -7px;
        position: absolute;
        left: 10px;
        z-index: 1;
    }
}

.form-control-plaintext {
    color: $theme-body-font-color;
}

.page-wrapper {
    position: relative;
    .page-header {
        max-width: 100vw;
        position: $main-header-position;
        top: $main-header-top;
        z-index: $main-header-z-index;
        transition: $sidebar-transition;
        background-color: $white;
        box-shadow: $main-header-shadow-1;
        .header-wrapper {
            width: 100%;
            align-items: center;
            padding: $header-wrapper-padding;
            position: relative;
            @media (max-width: 1399px) {
                padding: 22px 20px;
            }
            .search-full {
                background-color: $white;
                position: absolute;
                right: 0;
                z-index: 3;
                height: 0;
                width: 0;
                transform: scale(0);
                transition: all 0.3s ease;
                top: 0;
                .Typeahead-menu {
                    top: 90%;
                    background: $white;
                    width: calc(100% - 20px);
                    left: 50%;
                    transform: translateX(-50%);
                    max-height: 253px;
                }
                &.open {
                    height: 100%;
                    width: 100%;
                    animation: zoomIn 0.5s ease-in-out;
                    transform: scale(1);
                    transition: all 0.3s ease;
                }
                input {
                    line-height: 65px;
                    padding-left: 60px;
                    width: 100% !important;
                    &:focus {
                        outline: none !important;
                    }
                }
                .form-group {
                    .close-search {
                        position: absolute;
                        font-size: 15px;
                        right: 30px;
                        top: 30px;
                        color: $dark-gray;
                        cursor: pointer;
                    }
                    &:before {
                        position: absolute;
                        left: 30px;
                        top: 27px;
                        content: "\f002";
                        font-size: 16px;
                        color: $dark-gray;
                        font-family: FontAwesome;
                    }
                }
            }
            .nav-right {
                justify-content: flex-end;
                ul {
                    li {
                        svg {
                            vertical-align: $header-wrapper-nav-icon-align;
                            width: $header-wrapper-nav-icon-size;
                            height: $header-wrapper-nav-icon-size;
                            stroke: rgba($theme-body-font-color, 0.9);
                            fill: none;
                            path {
                                color: $header-wrapper-nav-icon-color;
                            }
                        }
                        .mode {
                            &.active {
                                svg {
                                    fill: $white;
                                }
                            }
                        }
                    }
                }
                &.right-header {
                    ul {
                        li {
                            .mode {
                                cursor: pointer;
                                text-align: center;
                                i {
                                    font-size: 18px;
                                    font-weight: 600;
                                    transition: all 0.3s ease;
                                    &.fa-lightbulb-o {
                                        font-weight: 500;
                                        font-size: 22px;
                                        transition: all 0.3s ease;
                                        animation: zoomIn 300ms ease-in-out;
                                    }
                                }
                            }
                            .header-search {
                                cursor: pointer;
                            }
                            .profile-media {
                                .media-body {
                                    margin-left: 15px;
                                    span {
                                        font-weight: 500;
                                    }
                                    p {
                                        font-size: 12px;
                                        line-height: 1;
                                        color: rgba($theme-body-font-color, 0.7);
                                    }
                                }
                            }
                        }
                    }
                }
                >ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    >li {
                        padding: 6px 10px;
                        display: inline-block;
                        position: relative;
                        .badge {
                            position: absolute;
                            right: -5px;
                            top: -6px;
                            padding: 2px 4px;
                            font-size: 11px;
                            font-weight: 700;
                            font-family: work-Sans, sans-serif;
                        }
                        span {
                            ul {
                                left: inherit;
                                right: -10px;
                                width: 130px;
                                &:before,
                                &:after {
                                    left: inherit;
                                    right: 10px;
                                }
                                li {
                                    display: block;
                                    a {
                                        font-size: 14px;
                                        color: $dark-color;
                                        i {
                                            margin-left: 10px;
                                            font-size: 13px;
                                            color: $dark-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .flag-icon {
                        font-size: 16px;
                    }
                }
                .bookmark-flip {
                    width: unset !important;
                    box-shadow: unset !important;
                    background-color: transparent !important;
                    overflow: initial;
                    &::after,
                    &::before {
                        display: none;
                    }
                    .bookmark-dropdown {
                        li {
                            padding: 0px;
                            .bookmark-content {
                                text-align: center;
                                .bookmark-icon {
                                    width: 52px;
                                    height: 52px;
                                    background-color: #f9f9f9;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 auto;
                                }
                                span {
                                    font-family: $font-roboto, $font-serif;
                                    font-size: 12px;
                                    font-weight: 500;
                                    letter-spacing: 0.5px;
                                    color: #222;
                                }
                            }
                        }
                    }
                }
                .flip-card {
                    width: 300px;
                    height: 300px;
                    background-color: transparent;
                    .flip-card-inner {
                        position: relative;
                        height: 100%;
                        text-align: center;
                        transition: transform 0.6s;
                        transform-style: preserve-3d;
                        .front,
                        .back {
                            position: absolute;
                            backface-visibility: hidden;
                            border-radius: 10px;
                            overflow: hidden;
                            box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
                            width: 300px;
                            height: 300px;
                            button {
                                background-color: $transparent-color;
                                color: $primary-color;
                                border: none;
                                &:focus {
                                    outline: $transparent-color;
                                }
                            }
                        }
                        .front {
                            background-color: $white;
                            transition: 0.3s;
                            svg {
                                stroke: $dark-color;
                                transition: all 0.3s ease;
                                &:hover {
                                    stroke: var(--theme-deafult);
                                    transition: all 0.3s ease;
                                }
                            }
                        }
                        .back {
                            background-color: $white;
                            transform: rotateY(180deg);
                            .Typeahead-menu {
                                max-height: 150px;
                            }
                            li {
                                &:first-child {
                                    margin-top: 20px;
                                    height: 150px !important;
                                }
                                &:last-child {
                                    position: absolute;
                                    bottom: -100px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                            button {
                                width: 100%;
                            }
                            .flip-back-content {
                                input {
                                    border-radius: 25px;
                                    padding: 6px 12px;
                                    width: 100%;
                                    border: 1px solid #ddd;
                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }
                        &.flipped {
                            transform: rotateY(180deg);
                            .front {
                                opacity: 0;
                            }
                        }
                    }
                }
                .onhover-show-div {
                    width: 330px;
                    top: 50px;
                    right: 0;
                    left: unset;
                    .dropdown-title {
                        padding: 20px;
                        text-align: center;
                        border-bottom: 1px solid rgba($dropdown-border-color, 0.3);
                    }
                    ul {
                        padding: 15px;
                    }
                    li {
                        padding: 15px;
                        p {
                            font-size: 14px;
                            letter-spacing: 0.3px;
                            margin-bottom: 0;
                        }
                        a {
                            text-decoration: underline;
                            text-transform: uppercase;
                            letter-spacing: 0.3px;
                            font-family: $font-roboto, $font-serif;
                        }
                        &+li {
                            margin-top: 8px;
                        }
                        &:last-child {
                            padding-bottom: 15px;
                        }
                        .media {
                            position: relative;
                            img {
                                width: 40px;
                                position: relative;
                            }
                            .status-circle {
                                left: 0;
                            }
                            .media-body {
                                >span {
                                    letter-spacing: 0.8px;
                                    padding-right: 10px;
                                    display: inline-block;
                                }
                                p {
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                    &:before,
                    &:after {
                        right: 10px !important;
                        left: unset !important;
                    }
                }
                .notification-box {
                    position: relative;
                }
                .cart-box {
                    position: relative;
                }
                .cart-dropdown {
                    ul {
                        padding: 0 0 15px;
                    }
                    li {
                        margin: 0 15px;
                        padding: 10px 0;
                        .media {
                            align-items: center;
                            .media-body {
                                margin-right: 20px;
                                [dir="rtl"] & {
                                    margin-right: unset;
                                    margin-left: 20px;
                                }
                            }
                        }
                        &+li {
                            margin-top: 0;
                            border-top: 1px solid rgba($dropdown-border-color, 0.1);
                        }
                        &:last-child {
                            padding: 20px 15px;
                            border-top: none;
                        }
                    }
                    p {
                        line-height: 1.3;
                        margin-top: 3px;
                        opacity: 0.6;
                    }
                    .close-circle {
                        position: absolute;
                        right: 0;
                        top: 0;
                        a {
                            width: 18px;
                            height: 18px;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        svg {
                            width: 14px;
                            stroke: $white;
                        }
                    }
                    .qty-box {
                        margin: 5px 0;
                        .input-group {
                            width: 80px;
                            background-color: transparent;
                            input {
                                background-color: transparent;
                            }
                            input,
                            .btn {
                                font-size: 10px;
                                font-family: $font-roboto, $font-serif;
                                font-weight: 700;
                            }
                            .btn {
                                padding: 0px 7px;
                                background-color: $off-white !important;
                                border-color: $off-white !important;
                                border-radius: 100%;
                                font-size: 14px;
                                color: $theme-body-font-color;
                            }
                        }
                    }
                    .total {
                        margin: 0;
                        padding: 15px;
                        background-color: #f9f9f9;
                        border: none;
                        h6 {
                            font-family: $font-roboto, $font-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            letter-spacing: 0.5px;
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                    .view-checkout {
                        text-decoration: unset;
                    }
                }
                .notification-box {
                    svg {
                        animation: swing 1.5s ease infinite;
                    }
                }
                .notification-dropdown {
                    top: 52px;
                    width: 300px;
                    right: -20px !important;
                    left: unset;
                    @media (max-width: 575.98px) {
                        width: calc(100vw - 30px);
                    }
                    li {
                        background-color: #f9f9f9;
                        border-radius: 5px;
                        &:last-child {
                            text-align: center;
                            background-color: transparent;
                        }
                        p {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            span {
                                font-size: 11px;
                                vertical-align: middle;
                            }
                        }
                    }
                    &:before,
                    &:after {
                        right: 28px !important;
                        left: unset !important;
                    }
                }
                .profile-dropdown {
                    width: 160px;
                    top: 57px;
                    left: -12px;
                    padding: 0 10px;
                    li {
                        padding: 10px !important;
                        a {
                            text-decoration: unset;
                        }
                        &:hover {
                            a {
                                span {
                                    color: var(--theme-deafult);
                                    transition: color 0.3s ease;
                                }
                                svg {
                                    stroke: var(--theme-deafult);
                                    transition: stroke 0.3s ease;
                                    path {
                                        stroke: var(--theme-deafult);
                                        transition: stroke 0.3s ease;
                                    }
                                }
                            }
                        }
                        +li {
                            margin-top: 0;
                        }
                        &:last-child {
                            padding-top: 10px !important;
                        }
                        span {
                            color: $dark-color;
                        }
                        svg {
                            width: 16px;
                            vertical-align: bottom;
                            margin-right: 10px;
                            stroke: $dark-color;
                        }
                    }
                }
            }
        }
    }
    .page-body-wrapper {
        background-color: $main-body-color;
        .page-body {
            min-height: calc(100vh - #{$header-size});
            margin-top: $header-size;
            padding: $page-body-padding;
            position: relative;
            transition: 0.5s;
            &:before {
                animation: fadeIn 0.5s ease-in-out;
            }
        }
        .page-title {
            padding: $page-title-padding;
            margin: 0 -27px 30px;
            background-color: $white;
            >.row {
                margin: 0;
                .col-6 {
                    padding: 0;
                }
            }
            .row {
                align-items: center;
                h3 {
                    font-size: $page-title-font-size;
                    margin-bottom: $page-title-margin-bottom;
                    text-transform: $page-title-text-tranform;
                    small {
                        display: $page-small-title-display;
                        font-size: $page-small-title-font-size;
                        margin-bottom: $page-small-title-margin-bottom;
                        text-transform: $page-small-title-text-transform;
                        color: $theme-body-sub-title-color;
                    }
                }
            }
            .breadcrumb {
                background-color: $transparent-color;
                padding: 0;
                margin-bottom: 0;
                align-items: center;
                justify-content: flex-end;
                li {
                    font-size: 13px;
                    letter-spacing: 1px;
                    font-family: $font-roboto, $font-serif;
                }
                .breadcrumb-item {
                    &+.breadcrumb-item {
                        &:before {
                            content: $breadcrumb-content;
                        }
                    }
                    a {
                        svg {
                            width: 16px;
                            height: 16px;
                            stroke: $dark-color;
                            vertical-align: text-top;
                        }
                    }
                    &.active {
                        color: var(--theme-deafult);
                        font-weight: 500;
                    }
                }
            }
        }
        div.sidebar-wrapper {
            .sidebar-main {
                .sidebar-links {
                    .simplebar-wrapper {
                        .simplebar-mask {
                            .simplebar-content-wrapper {
                                .simplebar-content {
                                    >li {
                                        a {
                                            svg {
                                                &.stroke-icon {
                                                    stroke: $dark-color;
                                                }
                                                &.fill-icon {
                                                    fill: $sidebar-fill-icon-color;
                                                }
                                            }
                                            &.active {
                                                svg {
                                                    transition: all 0.3s ease;
                                                    &.stroke-icon {
                                                        stroke: var(--theme-deafult);
                                                    }
                                                    &.fill-icon {
                                                        fill: var(--theme-deafult);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    li {
                                        a {
                                            text-transform: capitalize;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-wrapper {
        &[sidebar-layout="stroke-svg"] {
            .stroke-icon {
                display: inline-block;
            }
            .fill-icon {
                display: none;
            }
        }
        &[sidebar-layout="fill-svg"] {
            .stroke-icon {
                display: none;
            }
            .fill-icon {
                display: inline-block;
            }
        }
    }
    &.horizontal-wrapper {
        [data-simplebar] {
            position: relative;
        }
        .footer {
            margin-left: 0;
        }
        .header-logo-wrapper {
            width: 150px;
            .toggle-sidebar {
                display: none;
            }
        }
        .page-body-wrapper {
            .sidebar-wrapper {
                height: 54px;
                line-height: inherit;
                top: 79px;
                background: $white;
                box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
                overflow-x: hidden;
                z-index: 1;
                overflow-y: visible;
                position: fixed;
                width: 100vw;
                border-top: 1px solid $light-semi-gray;
                z-index: 3;
                li {
                    display: inline-block;
                }
                .badge {
                    position: absolute;
                    top: 2px;
                    right: 0px;
                    z-index: 1;
                }
                .logo-wrapper,
                .logo-icon-wrapper {
                    display: none;
                }
                .sidebar-main {
                    text-align: left;
                    position: relative;
                    .left-arrow,
                    .right-arrow {
                        position: absolute;
                        top: 0px;
                        padding: 15px;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                        cursor: pointer;
                        &.disabled {
                            display: none;
                        }
                    }
                    .left-arrow {
                        left: 0;
                    }
                    .right-arrow {
                        right: 0;
                    }
                    .sidebar-links {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        line-height: normal;
                        direction: ltr;
                        text-align: left;
                        display: -webkit-box !important;
                        padding: 0 5px;
                        transition: all 0.5s ease;
                        white-space: nowrap;
                        li {
                            &.sidebar-list {
                                a {
                                    &.active {
                                        .according-menu {
                                            i {
                                                &:before {
                                                    content: "-";
                                                }
                                            }
                                        }
                                    }
                                    .according-menu {
                                        display: none;
                                    }
                                }
                                ul.sidebar-submenu {
                                    li {
                                        a {
                                            &.active {
                                                color: var(--theme-deafult);
                                                &:after {
                                                    background-color: var(--theme-deafult);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .simplebar-wrapper {
                            .simplebar-mask {
                                .simplebar-content-wrapper {
                                    .simplebar-content {
                                        >li {
                                            position: relative;
                                            padding-top: 10px;
                                            padding-bottom: 10px;
                                            &:hover {
                                                >a {
                                                    >span {
                                                        color: var(--theme-deafult);
                                                        transition: all 0.3s ease;
                                                    }
                                                }
                                                a {
                                                    background-color: rgba($primary-color, 0.15%);
                                                    transition: all 0.3s ease;
                                                    svg {
                                                        &.stroke-icon {
                                                            stroke: var(--theme-deafult);
                                                            transition: all 0.3s ease;
                                                        }
                                                        &.fill-icon {
                                                            fill: var(--theme-deafult);
                                                            transition: all 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                            &:first-child {
                                                display: none !important;
                                            }
                                            >a {
                                                display: block;
                                                color: $theme-body-font-color;
                                                line-height: 23px;
                                                text-decoration: none;
                                                text-transform: capitalize;
                                                padding: 5px 15px;
                                                margin: 0 3px;
                                                letter-spacing: 0.04em;
                                                position: relative;
                                                border-radius: 5px;
                                                font-weight: 500;
                                                transition: all 0.3s ease;
                                                &.active {
                                                    //background-image: linear-gradient(90deg, var(--theme-deafult) 0%, #a26cf8 100%);
                                                    background-color: lighten($primary-color, 22%);
                                                    transition: all 0.3s ease;
                                                    svg,
                                                    span {
                                                        color: var(--theme-deafult);
                                                        transition: all 0.3s ease;
                                                    }
                                                }
                                                .badge {
                                                    position: absolute;
                                                    top: -5px;
                                                    margin-left: 1px;
                                                    right: 10px;
                                                    padding: 0.2em 0.7em;
                                                }
                                                svg {
                                                    width: 18px;
                                                    height: 18px;
                                                    vertical-align: middle;
                                                    margin-right: 10px;
                                                }
                                            }
                                            .sidebar-submenu {
                                                position: fixed;
                                                // left: 0;
                                                z-index: 4;
                                                box-shadow: 0 0 1px 0 $dark-gray;
                                                padding: 15px 0px 15px 0px;
                                                background: white;
                                                opacity: 1;
                                                visibility: visible;
                                                li {
                                                    border: 0;
                                                    float: none;
                                                    position: relative;
                                                    display: block;
                                                    a {
                                                        background: transparent;
                                                        padding: 4px 30px;
                                                        display: block;
                                                        transition: all 0.5s ease;
                                                        line-height: 1.9;
                                                        color: rgba(43, 43, 43, 0.6);
                                                        letter-spacing: 0.06em;
                                                        font-weight: 500;
                                                        font-family: Roboto;
                                                        transition: all 0.3s ease;
                                                        &:after {
                                                            left: 20px;
                                                            @extend %horizontal-after;
                                                        }
                                                        &:hover {
                                                            color: var(--theme-deafult);
                                                            letter-spacing: 1.5px;
                                                            transition: all 0.3s ease;
                                                            &:after {
                                                                background-color: var(--theme-deafult);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .mega-menu-container {
                                                position: fixed;
                                                right: 0;
                                                left: 0;
                                                margin: 0 auto;
                                                z-index: 4;
                                                box-shadow: 0 0 1px 0 $dark-gray;
                                                padding: 30px;
                                                background: white;
                                                width: 95%;
                                                border-radius: 4px;
                                                display: block !important;
                                                .mega-box {
                                                    width: 20%;
                                                    .link-section {
                                                        .submenu-title {
                                                            h5 {
                                                                text-transform: uppercase;
                                                                font-size: 14px;
                                                                margin-left: -13px;
                                                            }
                                                        }
                                                        .submenu-content {
                                                            box-shadow: none;
                                                            background-color: transparent;
                                                            position: relative;
                                                            display: block;
                                                            padding: 0;
                                                            li {
                                                                border: 0;
                                                                float: none;
                                                                position: relative;
                                                                display: block;
                                                                a {
                                                                    border: 0 !important;
                                                                    background: transparent;
                                                                    color: $theme-body-font-color;
                                                                    border-radius: 0 !important;
                                                                    text-transform: capitalize;
                                                                    padding: 3px 0;
                                                                    font-size: 13px;
                                                                    display: block;
                                                                    letter-spacing: 0.07em;
                                                                    line-height: 1.9;
                                                                    position: relative;
                                                                    transition: all 0.3s ease;
                                                                    &:hover {
                                                                        color: var(--theme-deafult);
                                                                        letter-spacing: 1.5px;
                                                                        transition: all 0.3s ease;
                                                                        &:after {
                                                                            color: var(--theme-deafult);
                                                                            transition: all 0.3s ease;
                                                                        }
                                                                    }
                                                                    &:after {
                                                                        position: absolute;
                                                                        content: "";
                                                                        left: -12px;
                                                                        top: 50%;
                                                                        width: 5px;
                                                                        height: 1px;
                                                                        background-color: $light-semi-font;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            &.active {
                                                .sidebar-submenu {
                                                    display: none !important;
                                                }
                                                &:hover {
                                                    .sidebar-submenu,
                                                    .mega-menu-container {
                                                        display: block !important;
                                                    }
                                                }
                                            }
                                            &:hover {
                                                .sidebar-submenu,
                                                .mega-menu-container {
                                                    display: block !important;
                                                }
                                            }
                                            &:hover {
                                                >a {
                                                    &:after {
                                                        left: 0;
                                                        right: 0;
                                                        opacity: 1;
                                                    }
                                                }
                                            }
                                            &.mega-menu {
                                                position: unset;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.overlay-white {
                    background-color: rgba(255, 255, 255, 0.08);
                }
            }
            .toggle-nav {
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                i {
                    font-size: 24px;
                }
            }
            .page-body {
                margin-top: 135px;
            }
            .sidebar-main-title {
                display: none !important;
            }
            &.scorlled {
                .sidebar-wrapper {
                    display: none;
                    transition: all 0.3s ease;
                }
            }
        }
        .page-header {
            width: 100%;
            box-shadow: none;
            .header-wrapper {
                .form-control-plaintext {
                    width: auto !important;
                }
                .left-header {
                    width: 30%;
                    @media (max-width: 1428px) and (min-width: 1324px) {
                        width: 32%;
                    }
                    @media (max-width: 1323px) and (min-width: 1200px) {
                        width: 37%;
                    }
                    @media (max-width: 1199px) and (min-width: 992px) {
                        width: 32%;
                    }
                }
                .nav-right {
                    @media (max-width: 1428px) and (min-width: 1324px) {
                        width: 55%;
                    }
                    @media (max-width: 1323px) and (min-width: 1200px) {
                        width: 40%;
                    }
                    @media (max-width: 1199px) and (min-width: 992px) {
                        width: 50%;
                    }
                }
            }
        }
        &.enterprice-type {
            footer {
                margin-left: 0;
            }
        }
    }
    &.material-icon {
        .card {
            border-radius: 30px;
            box-shadow: 0 0 20px rgba($primary-color, 0.1);
        }
    }
    .sidebar-main-title {
        h6 {
            font-size: 14px;
            font-weight: 600;
            color: $theme-body-sub-title-color;
            text-transform: uppercase;
            margin-bottom: 0;
            letter-spacing: 0.4px;
        }
    }
    // left sidebar css
    &.compact-wrapper {
        .page-header {
            margin-left: $sidebar-compact-width;
            width: calc(100% - $sidebar-compact-width);
            &.close_icon {
                margin-left: 90px;
                width: calc(100% - 90px);
            }
            .header-wrapper {
                .logo-wrapper {
                    display: none;
                }
                .toggle-sidebar {
                    display: none;
                }
            }
        }
        .page-body-wrapper {
            div {
                &.sidebar-wrapper {
                    position: fixed;
                    top: 0;
                    z-index: 9;
                    height: auto;
                    line-height: inherit;
                    background: $white;
                    width: $sidebar-compact-width;
                    text-align: left;
                    transition: 0.3s;
                    box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
                    .logo-wrapper,
                    .logo-icon-wrapper {
                        padding: 22px 30px;
                        box-shadow: -9px 0 20px rgba($semi-dark, 0.1);
                    }
                    .logo-icon-wrapper {
                        display: none;
                    }
                    &.close_icon {
                        width: auto;
                        transition: all 0.3s ease;
                        .badge {
                            opacity: 0;
                            width: 0;
                            padding: 0;
                        }
                        .sidebar-main {
                            .sidebar-links {
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                >li {
                                                    max-width: 86px;
                                                    margin: 0;
                                                    transition: all 0.3s ease;
                                                    .sidebar-submenu {
                                                        a {
                                                            display: none;
                                                        }
                                                    }
                                                    a {
                                                        text-align: center;
                                                        padding: 12px 14px;
                                                        transition: all 0.3s ease;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .sidebar-main-title {
                            display: none !important;
                            opacity: 0;
                            transition: all 0.3s ease;
                        }
                        .logo-wrapper {
                            display: none;
                        }
                        .logo-icon-wrapper {
                            text-align: right;
                            display: block;
                        }
                        &:hover {
                            width: 265px;
                            transition: all 0.3s ease;
                            .badge {
                                opacity: 1;
                                width: auto;
                                transition: all 0.3s ease;
                            }
                            .sidebar-main {
                                .sidebar-links {
                                    .simplebar-wrapper {
                                        .simplebar-mask {
                                            .simplebar-content-wrapper {
                                                .simplebar-content {
                                                    >li {
                                                        max-width: unset;
                                                        margin: initial;
                                                        transition: all 0.3s ease;
                                                        a {
                                                            text-align: left;
                                                            [dir="rtl"] & {
                                                                text-align: right;
                                                            }
                                                        }
                                                        .sidebar-submenu {
                                                            a {
                                                                display: block;
                                                            }
                                                        }
                                                        >a {
                                                            padding: 12px 15px;
                                                            transition: all 0.3s ease;
                                                            span {
                                                                display: inline-block;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .sidebar-main-title {
                                opacity: 1;
                                display: block !important;
                                transition: all 0.3s ease;
                            }
                            .logo-wrapper {
                                display: block;
                            }
                            .logo-icon-wrapper {
                                display: none;
                            }
                        }
                        .mobile-sidebar {
                            transform: translate(183px);
                        }
                        ~.page-body,
                        ~footer {
                            margin-left: 90px;
                            transition: 0.5s;
                        }
                        ~.footer-fix {
                            width: calc(100% - 90px);
                        }
                        .sidebar-main {
                            .sidebar-links {
                                height: calc(100vh - 118px);
                                .simplebar-wrapper {
                                    .simplebar-mask {
                                        .simplebar-content-wrapper {
                                            .simplebar-content {
                                                >li {
                                                    .sidebar-link {
                                                        &::before {
                                                            right: 20px;
                                                            left: unset;
                                                        }
                                                    }
                                                    a {
                                                        span {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .sidebar-list {
                                    .sidebar-title {
                                        &.active {
                                            ~.sidebar-submenu {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .according-menu {
                            display: none;
                        }
                    }
                    .sidebar-main {
                        .left-arrow,
                        .right-arrow {
                            display: none;
                        }
                        .sidebar-links {
                            height: $sidebar-height;
                            left: -300px;
                            z-index: 99;
                            transition: color 1s ease;
                            overflow: auto;
                            color: rgba(0, 0, 0, 0);
                            margin-top: 20px;
                            margin-bottom: 30px;
                            li {
                                a {
                                    font-weight: 500;
                                    svg {
                                        width: 18px;
                                        height: 18px;
                                        margin-right: 10px;
                                        vertical-align: -2px;
                                        float: none;
                                        transition: all 0.3s ease;
                                    }
                                    span {
                                        letter-spacing: 0.7px;
                                        text-transform: capitalize;
                                        color: rgba($theme-body-sub-title-color, 0.8);
                                    }
                                    &.link-nav {
                                        .according-menu {
                                            display: none;
                                        }
                                    }
                                }
                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                            &.opennav {
                                left: 0;
                            }
                            .back-btn {
                                display: block;
                                width: 100%;
                                padding: 0;
                                .mobile-back {
                                    padding: 20px;
                                    color: $theme-font-color;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    border-bottom: 1px solid #efefef;
                                    cursor: pointer;
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                }
                            }
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            >li {
                                                display: block;
                                                width: 100%;
                                                position: relative;
                                                &.sidebar-main-title {
                                                    padding: 25px 0 6px 20px;
                                                    margin-top: 10px;
                                                    [dir="rtl"] & {
                                                        padding: 25px 20px 6px 0;
                                                    }
                                                    &::before {
                                                        position: absolute;
                                                        content: "";
                                                        top: 0;
                                                        left: 50%;
                                                        background: var(--sidebar-border);
                                                        width: calc(100% - 40px);
                                                        height: 1px;
                                                        transform: translateX(-50%);
                                                    }
                                                    &:nth-child(2) {
                                                        margin-top: 0;
                                                        &::before {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                &.sidebar-list {
                                                    .badge {
                                                        position: absolute;
                                                        right: 50px;
                                                        top: 14.5px;
                                                        padding: 0.45em 0.8em 0.33em;
                                                        z-index: 1;
                                                        font-size: 10px;
                                                        letter-spacing: 0.7px;
                                                        font-weight: 600;
                                                        font-family: $font-roboto, $font-serif;
                                                        border-radius: 10px;
                                                    }
                                                    &:hover {
                                                        >a {
                                                            &:hover {
                                                                background-color: rgba($primary-color, 0.07);
                                                                transition: all 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                                &:hover {
                                                    .sidebar-link:not(.active) {
                                                        &:hover {
                                                            svg {
                                                                fill: rgba($primary-color, 0.1%);
                                                                stroke: var(--theme-deafult);
                                                                transition: all 0.3s ease;
                                                                &.fill-icon {
                                                                    fill: var(--theme-deafult);
                                                                    stroke: none;
                                                                }
                                                            }
                                                            span {
                                                                color: var(--theme-deafult);
                                                                transition: all 0.3s ease;
                                                            }
                                                            .according-menu {
                                                                i {
                                                                    color: var(--theme-deafult);
                                                                    transition: all 0.3s ease;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                a {
                                                    padding: 12px 20px;
                                                    position: relative;
                                                    color: #222222;
                                                    transition: all 0.3s ease;
                                                    .sub-arrow {
                                                        right: 20px;
                                                        position: absolute;
                                                        top: 10px;
                                                        i {
                                                            display: none;
                                                        }
                                                        &:before {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                                &:first-child {
                                                    display: none !important;
                                                }
                                                .sidebar-submenu {
                                                    width: 100%;
                                                    padding: 0;
                                                    position: relative !important;
                                                    &::before {
                                                        @extend %sidebar-before;
                                                    }
                                                    >li {
                                                        a {
                                                            padding-left: 48px !important;
                                                            font-weight: 400;
                                                        }
                                                    }
                                                    .submenu-title {
                                                        .according-menu {
                                                            top: 7px;
                                                        }
                                                    }
                                                    &.opensubmenu {
                                                        display: block;
                                                    }
                                                    li {
                                                        &:hover {
                                                            >a {
                                                                color: var(--theme-deafult);
                                                                transition: all 0.3s ease;
                                                            }
                                                        }
                                                        .nav-sub-childmenu {
                                                            display: none;
                                                            position: relative !important;
                                                            right: 0;
                                                            width: 100%;
                                                            padding: 0;
                                                            &.opensubchild {
                                                                display: block;
                                                            }
                                                            li {
                                                                a {
                                                                    padding: 6px 45px;
                                                                    padding-left: 56px !important;
                                                                    &:after {
                                                                        display: none;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        a {
                                                            padding: 7px 25px;
                                                            font-size: 14px;
                                                            color: rgba($theme-body-font-color, 0.6);
                                                            display: block;
                                                            position: relative;
                                                            letter-spacing: 0.06em;
                                                            font-weight: 500;
                                                            transition: all 0.3s ease;
                                                            white-space: nowrap;
                                                            overflow: hidden;
                                                            text-overflow: ellipsis;
                                                            &::before {
                                                                @extend %sidebar-active-dot;
                                                            }
                                                            &.active {
                                                                color: var(--theme-deafult);
                                                                &::before {
                                                                    opacity: 1;
                                                                    visibility: visible;
                                                                }
                                                            }
                                                            &:after {
                                                                left: 27px;
                                                                @extend %sidebar-after;
                                                            }
                                                            &:hover {
                                                                margin-left: 0;
                                                            }
                                                        }
                                                    }
                                                }
                                                .mega-menu-container {
                                                    padding: 0;
                                                    position: relative;
                                                    &::before {
                                                        @extend %sidebar-before;
                                                    }
                                                    &.opensubmenu {
                                                        display: block;
                                                    }
                                                    .mega-box {
                                                        width: 100%;
                                                        padding: 0;
                                                        .link-section {
                                                            &:hover {
                                                                h5 {
                                                                    color: var(--theme-deafult) !important;
                                                                    transition: all 0.3s ease;
                                                                }
                                                            }
                                                            .submenu-title {
                                                                position: relative;
                                                                h5 {
                                                                    margin-bottom: 0;
                                                                    line-height: 1.9;
                                                                    padding: 6px 25px 6px 45px;
                                                                    font-size: 14px;
                                                                    position: relative;
                                                                    color: rgba(43, 43, 43, 0.6);
                                                                    display: block;
                                                                    letter-spacing: 0.06em;
                                                                    font-weight: 500;
                                                                    font-family: Roboto;
                                                                    cursor: pointer;
                                                                    &:after {
                                                                        left: 28px;
                                                                        @extend %sidebar-after;
                                                                    }
                                                                    &::before {
                                                                        @extend %sidebar-active-dot;
                                                                    }
                                                                }
                                                                &.active {
                                                                    h5 {
                                                                        color: var(--theme-deafult);
                                                                        &::before {
                                                                            opacity: 1;
                                                                            visibility: visible;
                                                                        }
                                                                    }
                                                                }
                                                                .according-menu {
                                                                    top: 8px;
                                                                }
                                                            }
                                                            .submenu-content {
                                                                &.opensubmegamenu {
                                                                    li {
                                                                        a {
                                                                            margin-bottom: 0;
                                                                            line-height: 1.9;
                                                                            padding: 6px 25px 6px 45px;
                                                                            font-size: 14px;
                                                                            position: relative;
                                                                            color: rgba(43, 43, 43, 0.6);
                                                                            display: block;
                                                                            letter-spacing: 0.06em;
                                                                            font-weight: 500;
                                                                            font-family: Roboto;
                                                                            &:after {
                                                                                display: none;
                                                                            }
                                                                            &.active {
                                                                                color: var(--theme-deafult);
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                ul {
                                                                    li {
                                                                        a {
                                                                            line-height: 1.9;
                                                                            &:hover {
                                                                                margin-left: 0;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &.mega-menu {
                                                    .sidebar-title {
                                                        &.active {
                                                            ~.mega-menu-container {
                                                                display: block !important;
                                                                &::before {
                                                                    height: calc(100% - 9px);
                                                                    transition: height 0.5s;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                .sidebar-link {
                                                    transition: all 0.5s ease;
                                                    display: block;
                                                    &::after {
                                                        position: absolute;
                                                        content: "";
                                                        width: 4px;
                                                        height: 35px;
                                                        right: 0;
                                                        top: 50%;
                                                        transform: translateY(-50%);
                                                        background-color: $primary-color;
                                                        border-radius: 5px 0px 0px 5px;
                                                        opacity: 0;
                                                        visibility: hidden;
                                                        [dir="rtl"] & {
                                                            right: unset;
                                                            left: 0;
                                                        }
                                                    }
                                                    &.active {
                                                        transition: all 0.5s ease;
                                                        position: relative;
                                                        margin-bottom: 10px;
                                                        background-color: lighten($primary-color, 27%);
                                                        &::after {
                                                            opacity: 1;
                                                            visibility: visible;
                                                        }
                                                        ~.sidebar-submenu {
                                                            &::before {
                                                                height: calc(100% - 8px);
                                                                transition: height 0.5s;
                                                            }
                                                        }
                                                        .according-menu {
                                                            i {
                                                                color: var(--theme-deafult);
                                                            }
                                                        }
                                                        span {
                                                            color: var(--theme-deafult);
                                                            transition: all 0.3s ease;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .mega-box {
                &.col {
                    flex-basis: unset;
                }
            }
            .according-menu {
                position: absolute;
                right: 20px;
                top: 13px;
                display: block;
                i {
                    vertical-align: middle;
                    color: rgba($theme-body-sub-title-color, 0.8);
                    font-weight: 500;
                }
            }
            .main-menu {
                &.border-section {
                    border: none;
                }
                .menu-left {
                    .main-menu-right {
                        .toggle-nav {
                            position: absolute;
                            z-index: 2;
                            right: 145px;
                            top: 6px;
                            .sidebar-name {
                                font-size: 18px;
                                padding-left: 5px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            .page-body {
                min-height: calc(100vh - 80px);
                margin-top: 80px;
                margin-left: 65px; // OCULTAR PARA SIDE BAR TOGGLE ACTIVADO
                margin-right: 65px; // OCULTAR PARA SIDE BAR TOGGLE ACTIVADO
                // margin-left: $sidebar-compact-width; // OCULTAR PARA SIDE BAR TOGGLE DESACTIVADO
                // animation: pagebody 1000ms ease-in-out;
            }
        }
        &.dark-sidebar,
        &.color-sidebar {
            .sidebar-main-title {
                h6 {
                    color: $dark-all-font-color;
                }
            }
            .toggle-sidebar {
                svg {
                    stroke: var(--theme-deafult);
                }
            }
            .page-body-wrapper {
                div.sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            >li {
                                                a {
                                                    svg {
                                                        &.stroke-icon {
                                                            stroke: $dark-all-font-color;
                                                        }
                                                        &.fill-icon {
                                                            fill: $dark-all-font-color;
                                                        }
                                                    }
                                                    &.active {
                                                        svg {
                                                            &.stroke-icon {
                                                                stroke: var(--theme-deafult);
                                                            }
                                                            &.fill-icon {
                                                                fill: var(--theme-deafult);
                                                            }
                                                        }
                                                    }
                                                }
                                                &.sidebar-main-title {
                                                    &::before {
                                                        background: $dark-card-border;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div.sidebar-wrapper {
                background-color: $dark-card-background;
                .sidebar-main {
                    .sidebar-links {
                        li {
                            a {
                                span {
                                    color: $dark-all-font-color;
                                }
                                svg {
                                    &.stroke-icon {
                                        stroke: $dark-all-font-color;
                                    }
                                    &.fill-icon {
                                        fill: $dark-all-font-color;
                                    }
                                }
                            }
                        }
                        .simplebar-wrapper {
                            .simplebar-mask {
                                .simplebar-content-wrapper {
                                    .simplebar-content {
                                        >li {
                                            .mega-menu-container {
                                                .mega-box {
                                                    .link-section {
                                                        .submenu-title {
                                                            h5 {
                                                                color: $dark-all-font-color;
                                                            }
                                                        }
                                                        .submenu-content {
                                                            &.opensubmegamenu {
                                                                li {
                                                                    a {
                                                                        color: $dark-all-font-color;
                                                                        font-weight: 400;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                &::after {
                                                    background-color: $light-all-font-color;
                                                }
                                            }
                                            .sidebar-link {
                                                &.active {
                                                    background-color: rgba($primary-color, 0.2);
                                                    svg {
                                                        color: var(--theme-deafult);
                                                        stroke: var(--theme-deafult);
                                                    }
                                                }
                                            }
                                            .sidebar-submenu {
                                                li {
                                                    a {
                                                        color: $dark-all-font-color;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .sidebar-list {
                            ul.sidebar-submenu {
                                li {
                                    a {
                                        span {
                                            color: $dark-all-font-color;
                                        }
                                        &.active {
                                            color: var(--theme-deafult);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .logo-wrapper {
                    .for-light {
                        display: none;
                    }
                    .for-dark {
                        display: block;
                    }
                }
            }
            .according-menu {
                i {
                    color: $dark-all-font-color;
                }
            }
        }
        &.color-sidebar {
            div.sidebar-wrapper {
                background-color: #2f3c4e;
            }
        }
    }
    // compact icon css
    &.compact-sidebar {
        ~.bg-overlay1 {
            transition: 0.8s;
            &.active {
                z-index: 8;
                height: 100vh;
                width: 100vw;
                background-color: rgba($black, 0.2);
                position: fixed;
                top: 0;
            }
        }
        .toggle-sidebar {
            position: relative;
            right: unset;
            top: unset;
            margin-right: 15px;
        }
        .sidebar-main-title {
            display: none;
        }
        .logo-wrapper {
            display: none;
        }
        .logo-icon-wrapper {
            display: block;
            text-align: center;
            padding: 24px 30px;
            box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
        }
        .page-header {
            margin-left: 150px;
            width: calc(100% - 150px);
            .header-wrapper {
                .logo-wrapper {
                    display: none;
                }
                .left-header {
                    width: 30%;
                    @media (max-width: 1578px) and (min-width: 1274px) {
                        width: 38%;
                    }
                    @media (max-width: 1273px) and (min-width: 1200px) {
                        width: 45%;
                    }
                    @media (max-width: 991px) and (min-width: 768px) {
                        width: 36%;
                    }
                    @media (max-width: 767px) and (min-width: 756px) {
                        width: 28%;
                    }
                    h6 {
                        @media (max-width: 1199px) {
                            width: 132px;
                        }
                        @media (max-width: 767px) {
                            width: 83px;
                        }
                    }
                }
            }
            &.close_icon {
                margin-left: 0;
                width: calc(100% - 0px);
            }
        }
        .page-body-wrapper {
            .page-body {
                margin-top: 80px;
                margin-left: 150px;
            }
            .footer {
                margin-left: 150px;
            }
            div.sidebar-wrapper {
                position: fixed;
                top: 0;
                z-index: 9;
                >div {
                    height: auto;
                    line-height: inherit;
                    background: $white;
                    width: 150px;
                    text-align: left;
                    transition: 0.3s;
                    box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
                }
                .sidebar-main {
                    .left-arrow,
                    .right-arrow {
                        display: none;
                    }
                    .sidebar-links {
                        margin: 30px 0;
                        height: calc(100vh - 113px);
                        overflow: auto;
                        li {
                            a {
                                color: $dark-color;
                                span {
                                    text-transform: capitalize;
                                    color: $dark-color;
                                    font-weight: 500;
                                    letter-spacing: 1px;
                                    font-size: 14px;
                                }
                                &.active {
                                    background: rgba($primary-color, 0.1);
                                    color: $primary-color;
                                    border-radius: 20px;
                                    margin: 0 10px;
                                    span {
                                        color: $primary-color;
                                    }
                                    ~.sidebar-submenu,
                                    ~.mega-menu-container {
                                        animation: fadeInLeft 300ms ease-in-out;
                                    }
                                }
                            }
                            .sidebar-title {
                                display: block;
                                padding: 20px 0;
                                border-bottom: 1px solid #f3f3f3;
                            }
                            .sidebar-submenu,
                            .mega-menu-container {
                                position: fixed;
                                top: 0px;
                                height: calc(100vh - 0px);
                                overflow: auto;
                                left: 150px;
                                width: 230px;
                                background-color: $white;
                                box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
                                transition: all 0.5s ease-in-out;
                                display: none;
                                padding-top: 20px;
                                z-index: -1;
                                li {
                                    a {
                                        padding: 15px 15px 15px 40px;
                                        display: block;
                                        position: relative;
                                        border-bottom: 1px solid #f1f1f1;
                                        .sub-arrow {
                                            display: none;
                                        }
                                        .according-menu {
                                            position: absolute;
                                            right: 20px;
                                            top: 16px;
                                        }
                                        &.active {
                                            background-color: rgba($primary-color, 0.1);
                                            color: $primary-color;
                                            transition: all 0.3s ease;
                                        }
                                    }
                                    .submenu-content {
                                        li {
                                            a {
                                                &.active {
                                                    background-color: $transparent-color;
                                                }
                                            }
                                        }
                                    }
                                }
                                >li {
                                    >a {
                                        &::after {
                                            position: absolute;
                                            content: "";
                                            left: 20px;
                                            top: 48%;
                                            width: 8px;
                                            border-top: 2px solid rgba($theme-body-sub-title-color, 0.4);
                                        }
                                    }
                                }
                                .mega-box {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                    padding: 0;
                                    .link-section {
                                        text-align: left;
                                        .submenu-title {
                                            h5 {
                                                position: relative;
                                                font-size: 14px;
                                                font-weight: 400;
                                                padding: 15px 15px 15px 40px;
                                                letter-spacing: 1px;
                                                margin-bottom: 0;
                                                &::after {
                                                    position: absolute;
                                                    content: "";
                                                    left: 20px;
                                                    top: 48%;
                                                    width: 6px;
                                                    border-top: 2px solid #595c60;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .sidebar-list {
                            position: relative;
                            .badge {
                                position: absolute;
                                left: 53%;
                                top: 7px;
                                font-weight: 500;
                                font-size: 9px;
                            }
                            .sidebar-link {
                                text-align: center;
                                svg {
                                    margin: 0 auto 6px;
                                    width: 24px;
                                    height: 24px;
                                }
                                >span {
                                    display: block;
                                    font-weight: 500;
                                    letter-spacing: 1px;
                                    font-size: 14px;
                                    word-break: break-word;
                                }
                                .according-menu {
                                    display: none;
                                }
                            }
                        }
                        .mega-menu {
                            text-align: center;
                            padding: 0;
                            svg {
                                width: 24px;
                                height: 24px;
                            }
                            .according-menu {
                                position: absolute;
                                right: 20px;
                                top: 13px;
                            }
                        }
                    }
                }
                &[sidebar-layout="stroke-svg"] {
                    .sidebar-links {
                        .mega-menu {
                            svg {
                                &.stroke-icon {
                                    display: block;
                                    margin: 0 auto 6px;
                                }
                                &.fill-icon {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                &[sidebar-layout="fill-svg"] {
                    .sidebar-links {
                        .mega-menu {
                            svg {
                                &.stroke-icon {
                                    display: none;
                                }
                                &.fill-icon {
                                    display: block;
                                    margin: 0 auto 6px;
                                }
                            }
                        }
                    }
                }
                &.close_icon {
                    transform: translateX(-150px);
                    ~.page-body,
                    ~.footer {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
    // compact small css
    &.compact-small {
        .toggle-sidebar {
            position: relative;
            right: unset;
            top: unset;
            margin-right: 15px;
        }
        .sidebar-main-title,
        .logo-wrapper {
            display: none;
        }
        .logo-icon-wrapper {
            display: block;
            text-align: center;
            padding: 27px 30px;
            box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
        }
        .page-header {
            margin-left: 90px;
            width: calc(100% - 90px);
            .header-wrapper {
                .logo-wrapper {
                    display: none;
                }
            }
            &.close_icon {
                margin-left: 0;
                width: calc(100% - 0px);
            }
        }
        .page-body-wrapper {
            .page-body {
                margin-left: 90px;
            }
            .footer {
                margin-left: 90px;
            }
            div.sidebar-wrapper {
                >div {
                    width: 90px;
                }
                .sidebar-main {
                    .sidebar-links {
                        @media (max-width: 420px) {
                            height: calc(100vh - 105px);
                        }
                        li {
                            .sidebar-title {
                                display: block;
                                padding: 15px 0;
                            }
                            .sidebar-submenu,
                            .mega-menu-container {
                                left: 90px;
                                li {
                                    a {
                                        padding: 10px 15px 10px 40px;
                                        .sub-arrow {
                                            display: none;
                                        }
                                        .according-menu {
                                            top: 11px;
                                        }
                                    }
                                }
                            }
                        }
                        .sidebar-list {
                            .badge {
                                display: none;
                            }
                            .sidebar-link {
                                svg {
                                    margin: 0 auto;
                                    width: 20px;
                                    height: 20px;
                                }
                                >span {
                                    display: none;
                                }
                            }
                        }
                        .mega-menu {
                            svg {
                                margin: 0 auto;
                            }
                            a {
                                >span {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                &.close_icon {
                    transform: translateX(-150px);
                    ~.page-body,
                    ~.footer {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
    // only body css
    &.only-body {
        background-color: #f2f0fa;
        .page-body-wrapper {
            background-color: #f2f0fa;
        }
        .page-header {
            position: relative;
            .header-logo-wrapper {
                display: none;
            }
            .header-wrapper {
                padding: 0 !important;
                .left-header {
                    display: none;
                }
                .nav-right {
                    position: absolute;
                    right: 60px;
                    top: 58px;
                }
                .search-full {
                    top: 37px;
                    width: calc(100% - 50px);
                    left: 25px;
                    [dir="rtl"] & {
                        left: unset;
                        right: 25px;
                    }
                    .form-group {
                        .Typeahead {
                            .u-posRelative {
                                background-color: $white;
                                border-radius: 15px;
                            }
                        }
                    }
                    .Typeahead-menu {
                        top: 78px;
                        width: calc(100% - 30px);
                        margin: 0 auto;
                        right: 0;
                    }
                }
            }
        }
        .page-body-wrapper {
            .page-title {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
        .sidebar-wrapper,
        footer {
            display: none;
        }
        .page-body-wrapper {
            .page-body {
                margin-top: 0px;
                min-height: calc(100vh - 0px);
            }
            .page-title {
                padding-left: 30px;
                padding-right: 30px;
                margin: 30px 0;
                border-radius: 15px;
                @media (max-width: 991.98px) {
                    padding-left: 20px;
                    padding-right: 20px;
                    margin: 20px 0;
                }
                >.row {
                    .col-6 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .breadcrumb {
                    margin-top: 5px;
                    justify-content: flex-start;
                }
            }
        }
    }
    // modern css
    &.modern-type {
        .progress-chart-wrap {
            margin-left: -22px;
            [dir="rtl"] & {
                margin-left: unset;
                margin-right: -22px;
            }
        }
        .ecommerce-widget {
            border: 1px solid lighten($primary-color, 22%) !important;
        }
        .file-sidebar .pricing-plan {
            border: 1px solid lighten($primary-color, 22%) !important;
        }
        .alert-primary {
            color: $primary-color;
        }
        .project-box {
            background-color: lighten($primary-color, 22%);
        }
        .nav-tabs .nav-link.active {
            background-color: transparent;
        }
        .simplebar-mask {
            top: 10px;
        }
        .page-header {
            margin-left: 0px;
            width: calc(100% - 0px);
            &.close_icon {
                margin-left: 0px;
                width: calc(100% - 0px);
            }
            .header-wrapper {
                .left-header {
                    width: 30%;
                    @media (max-width: 1428px) and (min-width: 1200px) {
                        width: 37%;
                    }
                    @media (max-width: 818px) {
                        width: 36%;
                    }
                }
                .nav-right {
                    @media (max-width: 1669px) {
                        width: 50%;
                    }
                    @media (max-width: 1428px) and (min-width: 1200px) {
                        width: 40%;
                    }
                }
                .header-logo-wrapper {
                    display: flex;
                    align-items: center;
                }
                .logo-wrapper {
                    display: block;
                    margin-right: 30px;
                }
                .toggle-sidebar {
                    display: block;
                    position: relative;
                    top: unset;
                    right: unset;
                    margin-right: 15px;
                }
            }
        }
        .page-body-wrapper {
            .page-title {
                background-color: transparent;
                padding-bottom: 0;
            }
            div.sidebar-wrapper {
                margin: 30px !important;
                top: 80px;
                bottom: 0;
                border-radius: 15px;
                .logo-wrapper,
                .logo-icon-wrapper {
                    display: none;
                }
                .sidebar-main {
                    .sidebar-links {
                        height: calc(100vh - 160px);
                    }
                }
                &.close_icon {
                    .logo-icon-wrapper {
                        display: none;
                    }
                    ~.page-body {
                        margin-left: 0px;
                    }
                    ~footer {
                        margin-left: 0px;
                        margin-right: 0px;
                    }
                    &:hover {
                        .logo-wrapper {
                            display: none;
                        }
                    }
                }
            }
            .page-body {
                margin-left: 296px;
                .container-fluid {
                    max-width: 1500px;
                }
            }
            .footer {
                margin-left: 360px;
                margin-right: 70px;
                border-radius: 10px;
                @media (max-width: 991px) {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }
    // material css
    &.material-type {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 300px;
            background-image: $gradient-bg-primary;
        }
        .card {
            box-shadow: 0 0 20px rgba($black, 0.05);
        }
        .page-header {
            position: relative;
            max-width: 1560px;
            margin: 50px auto 0;
            border-radius: 15px 15px 0 0;
            .header-wrapper {
                .left-header {
                    width: 31%;
                    @media (max-width: 1600px) {
                        width: 33%;
                    }
                    @media (max-width: 1366px) {
                        width: 38%;
                    }
                    @media (max-width: 1199px) {
                        width: 30%;
                    }
                }
                .nav-right {
                    @media (max-width: 1600px) {
                        width: 40%;
                    }
                    @media (max-width: 1199px) {
                        width: 45%;
                    }
                }
            }
        }
        .page-body-wrapper {
            background-image: url(../../images/other-images/boxbg.jpg);
            background-blend-mode: overlay;
            background-color: rgba($white, 0.5);
            .sidebar-wrapper {
                position: relative;
                max-width: 1560px;
                margin: 0 auto;
                top: unset;
                height: 56px;
            }
            .page-body {
                margin: 0 auto;
                max-width: 1560px;
                background-color: $body-color;
                box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
                border-radius: 0 0 15px 15px;
            }
        }
        .footer {
            margin-top: 40px;
        }
        &.compact-wrapper {
            .page-header {
                .header-wrapper {
                    .left-header {
                        width: 38%;
                    }
                    .nav-right {
                        @media (max-width: 991px) {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
    &.advance-layout {
        background-blend-mode: overlay;
        background-color: $main-body-color;
        .card {
            border-radius: 5px;
            box-shadow: none;
            border: 1px solid #ecf3fa;
        }
        .page-header {
            position: relative;
            background-color: $white;
            margin: 0 auto;
        }
        .page-body-wrapper {
            background: transparent;
            .sidebar-wrapper {
                position: relative !important;
                top: unset !important;
                height: unset;
                box-shadow: none;
                border-top: 1px solid #efefef;
                border-bottom: 1px solid #efefef;
                >div {
                    overflow-x: hidden;
                }
            }
            .page-body {
                margin-top: 0 !important;
            }
        }
    }
}

.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul {
    position: relative;
    li {
        a {
            text-decoration: none;
            text-transform: capitalize;
        }
    }
}

@keyframes pagebody {
    from {
        opacity: 0;
        transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
    }
    50% {
        opacity: 1;
    }
}

// sidebar filter
.md-sidebar {
    position: relative;
    .md-sidebar-toggle {
        display: none;
        width: fit-content;
        text-transform: capitalize;
        margin-bottom: 20px;
    }
}

// Responsive menu css start //
// @media screen and (max-width: 1780px) {
//   .page-wrapper {
//     &.horizontal-wrapper {
//       .page-body-wrapper {
//         .sidebar-wrapper {
//           .sidebar-main {
//             .sidebar-links {
//               .simplebar-wrapper {
//                 .simplebar-mask {
//                   .simplebar-content-wrapper {
//                     .simplebar-content {
//                        >li {
//                          .mega-menu-container {
//                            right: -18%;
//                            width: 91%;
//                          }
//                        }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 1480px) {
    .page-wrapper {
        &.horizontal-wrapper {
            .page-body-wrapper {
                .sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            >li {
                                                .mega-menu-container {
                                                    right: -18%;
                                                    width: 91%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1460px) {
    .page-wrapper {
        &.horizontal-wrapper {
            .page-body-wrapper {
                &.sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            >li {
                                                .mega-menu-container {
                                                    right: -54%;
                                                    width: 75%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar-wrapper {
        .sidebar-main {
            .sidebar-links {
                .simplebar-wrapper {
                    .simplebar-mask {
                        .simplebar-content-wrapper {
                            .simplebar-content {
                                >li {
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .page-wrapper {
        &.horizontal-wrapper {
            .page-body-wrapper {
                &.sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            >li {
                                                .mega-menu-container {
                                                    right: -49%;
                                                    width: 70%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .profile-dropdown {
        &.onhover-show-div {
            right: 0;
            left: unset !important;
            &:before,
            &:after {
                right: 10px !important;
                left: unset !important;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .page-wrapper {
        .page-header {
            .header-wrapper {
                .nav-right {
                    >ul {
                        >li {
                            padding: 6px;
                        }
                    }
                }
            }
        }
    }
    .mobile-title {
        &.d-none {
            display: flex !important;
            justify-content: space-between;
            padding: 20px 30px 17px;
            border-bottom: 1px solid #ddd;
            margin: 0 -15px;
            h5 {
                color: var(--theme-deafult);
            }
            svg {
                stroke: var(--theme-deafult) !important;
            }
        }
    }
    .page-wrapper {
        &.horizontal-wrapper {
            .page-body-wrapper {
                &.sidebar-wrapper {
                    .sidebar-main {
                        .sidebar-links {
                            .simplebar-wrapper {
                                .simplebar-mask {
                                    .simplebar-content-wrapper {
                                        .simplebar-content {
                                            >li {
                                                .mega-menu-container {
                                                    right: -35%;
                                                    width: 56%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .page-header {
        .header-wrapper {
            .nav-right {
                .notification-dropdown {
                    top: 46px;
                }
            }
        }
    }
    .responsive-btn {
        display: none;
    }
    .page-wrapper {
        &.horizontal-wrapper {
            .page-body-wrapper {
                .sidebar-main {
                    .sidebar-links {
                        .simplebar-mask {
                            top: 0;
                        }
                        .simplebar-wrapper {
                            .simplebar-mask {
                                .simplebar-content-wrapper {
                                    .simplebar-content {
                                        >li {
                                            .sidebar-submenu {
                                                width: 200px;
                                                opacity: 0;
                                                visibility: hidden;
                                                border-radius: 5px;
                                                transition: all 0.5s ease;
                                                a {
                                                    .sub-arrow {
                                                        position: absolute;
                                                        right: 20px;
                                                        top: 5px;
                                                        color: $light-semi-font;
                                                        font-weight: 100;
                                                        transition: all 0.5s ease;
                                                        font-size: 15px;
                                                        top: 5px;
                                                    }
                                                }
                                                li {
                                                    .nav-sub-childmenu {
                                                        display: none;
                                                        border-radius: 4px;
                                                    }
                                                    &:hover {
                                                        .nav-sub-childmenu {
                                                            display: block;
                                                            position: absolute;
                                                            left: 200px;
                                                            background-color: $white;
                                                            top: -10px;
                                                            padding: 15px 0px 15px 0px;
                                                            box-shadow: 0 0 1px 0 #898989;
                                                        }
                                                    }
                                                    a {
                                                        &:hover {
                                                            .sub-arrow {
                                                                right: 16px;
                                                                transition: all 0.5s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .mega-menu-container {
                                                opacity: 0;
                                                visibility: hidden;
                                                transition: all 0.5s ease;
                                            }
                                            &.hoverd {
                                                .sidebar-submenu {
                                                    opacity: 1;
                                                    visibility: visible;
                                                    margin-top: 8px;
                                                    transition: all 0.5s ease;
                                                }
                                            }
                                            &:hover {
                                                .mega-menu-container {
                                                    opacity: 1;
                                                    visibility: visible;
                                                    margin-top: 20px;
                                                    transition: all 0.5s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .toggle-nav {
                    display: none;
                }
                .mobile-back {
                    display: none !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    // material icon css
    .page-wrapper {
        &.material-icon {
            position: relative;
            &::before {
                position: absolute;
                content: "";
                left: 0;
                width: 100%;
                height: 200px;
                background: $gradient-bg-primary;
            }
            .toggle-sidebar {
                color: rgba($white, 0.8);
                &:hover {
                    >svg {
                        color: $white !important;
                        stroke: $white !important;
                    }
                }
            }
            .page-header {
                position: relative;
                background: transparent;
                padding-left: 90px;
                max-width: 1660px;
                margin: 0 auto;
                width: calc(100% - 0px);
                box-shadow: none;
                .f-light {
                    color: $white;
                }
                .header-wrapper {
                    .nav-right {
                        ul {
                            li {
                                svg {
                                    stroke: $white;
                                }
                                .profile-dropdown {
                                    li {
                                        svg {
                                            stroke: $theme-body-font-color;
                                        }
                                    }
                                }
                            }
                        }
                        >ul {
                            >li {
                                color: $white;
                                .notification-box,
                                .cart-box {
                                    svg {
                                        stroke: $white;
                                    }
                                }
                                >a {
                                    >svg {
                                        stroke: $white;
                                    }
                                }
                                >svg {
                                    stroke: $white;
                                }
                            }
                        }
                        .language-nav {
                            .more_lang {
                                color: $theme-body-font-color;
                            }
                        }
                        .onhover-show-div {
                            color: $theme-body-font-color;
                        }
                        &.right-header {
                            ul {
                                li {
                                    .profile-media {
                                        .media-body {
                                            p {
                                                color: rgba($white, 0.7);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.close_icon {
                    padding-left: 0;
                    margin: 0 auto !important;
                }
            }
            .page-body-wrapper {
                .page-body {
                    margin-top: 0 !important;
                    padding-left: 90px;
                    max-width: 1660px;
                    margin: 0 auto;
                }
                div.sidebar-wrapper {
                    &.close_icon {
                        ~.page-body {
                            padding-left: 15px;
                            margin: 0 auto !important;
                        }
                    }
                }
                .page-title {
                    color: $white;
                    background-color: transparent;
                    border-bottom: none;
                    box-shadow: none;
                    .breadcrumb {
                        .breadcrumb-item {
                            a {
                                svg {
                                    stroke: $white;
                                }
                            }
                            &.active {
                                color: rgba($white, 0.6);
                            }
                            +.breadcrumb-item {
                                &::before {
                                    color: rgba($white, 0.8);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    // material icon css
    .page-wrapper {
        &.material-icon {
            &::before {
                height: 240px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .sidebar-wrapper {
        .menu {
            .brand-logo {
                img {
                    width: 120px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .toggle-nav {
        i {
            font-size: 20px;
        }
    }
}


/**=====================
    78. Sidebar CSS Ends
==========================**/